import React from 'react'
import { connect } from 'react-redux'
import { changeSearchBoxText, changeSearchTerm, changeMeetingType } from '../state/reduxApp'
import Home from '../components/home/home'

const Template = ({ dispatchSearchBoxText, dispatchSearchTerm, dispatchMeetingType, pageContext: { boxText, searchTerm, meetingType } }) => {
  if (boxText) dispatchSearchBoxText(boxText)
  if (searchTerm) dispatchSearchTerm(searchTerm)
  if (meetingType) dispatchMeetingType(meetingType)

  return <Home />
}

const mapDispatchToProps = dispatch => ({
  dispatchSearchBoxText: callbackLink => dispatch(changeSearchBoxText(callbackLink)),
  dispatchSearchTerm: callbackLink => dispatch(changeSearchTerm(callbackLink)),
  dispatchMeetingType: callbackLink => dispatch(changeMeetingType(callbackLink)),
})

export default connect(null, mapDispatchToProps)(Template)
